// Colors

$primary-color: #114bab;
$primary-color-lg: #e4e4e4;
$primary-color-accent: #f0f0f0;
$secondary-color-accent: #D3F4FF;
$secondary-color: #c15e21;
$secondary-color-lg: #992aac;

._ {
  color: #8dd3c7;
  color: #ffffb3;
  color: #bebada;
  color: #fb8072;
  color: #80b1d3;
  color: #fdb462;
  color: #b3de69;
  color: #fccde5;
  color: #d9d9d9;
  color: #bc80bd;
}

@import 'src/assets/styles/partials/ripple';
@import 'src/assets/styles/partials/react-tooltip';

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  width: 100%;
  height: 100vh;
}

* {
  outline: 2px solid invert;
  outline-offset: 2px;
  outline: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: darken(#5492cd, 5);
}

